export const InterviewProcessOptions = [
  {
    name: "Analyse Resume",
    id: "analyse-resume"
  },
  {
    name: "Ask Preliminary Questions",
    id: "prelim-questions"
  },
  {
    name: "HR Pages",
    id: "hr-Pages"
  },
  {
    name: "Coding Challenge - VueJS",
    id: "coding-challenge-vuejs"
  },
  {
    name: "Coding Challenge - Angular",
    id: "coding-challenge-angular"
  },
  {
    name: "Coding Challenge - React",
    id: "coding-challenge-react"
  },
  {
    name: "Coding Challenge - Svelte",
    id: "coding-challenge-svelte"
  },
  {
    name: "Coding Challenge - Python",
    id: "coding-challenge-python"
  },
  {
    name: "Coding Challenge - JavaScript",
    id: "coding-challenge-javascript"
  },
  {
    name: "Technical Pages - FrontEnd",
    id: "technical-Pages-frontend"
  },
  {
    name: "Technical Pages - BackEnd",
    id: "technical-Pages-backend"
  }
];
